import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

interface HeaderContentInterface {
  title: string;
  icon?: string;
}

@Component({
  selector: 'fina-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() content: HeaderContentInterface = { title: "", icon: "" }
}
