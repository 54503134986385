import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http'
import { tap } from 'rxjs/operators';
import { User } from '../models'
import { Router } from '@angular/router';
import { UserService } from '../services';
import { ToastService } from '@common/services/toast.service';
import { PlansService } from '@common/services/plans.service';
import { CommerceService } from '@common/services/commerce.service';
import { SalesChannelsService } from '@common/services/sales-channels.service';
import posthog from 'posthog-js'
import { NewsService } from '@common/services/news.service';

interface AuthResponseData {
    _id: string,
    email: string,
    name: string,
    lastName: string,
    tenantId: string,
    token: string,
    expiresIn: number,
    roles: string[],
    phone: string,
    birthday: Date,
    location: string,
    enabled: boolean,
    status: string,
    permissions: Object,
    showNews: boolean,
    instructionsNotToShow: string[]
}

@Injectable({
    providedIn: 'root',
})

export class AuthService {

    private tokenExpirationTimer: any;
    API_URL = environment.API_URL;

    constructor(
        private http: HttpClient,
        private router: Router,
        private userService: UserService,
        private commerceService: CommerceService,
        private toastService: ToastService,
        private plansService: PlansService,
        private salesChannelsService: SalesChannelsService,
        private newsService: NewsService
    ) {
        // Add visibility change listener
        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                this.checkSession();
            }
        });
    }

    getAuth$(): Observable<{}> {
        return of({});
    }

    isTenant$(): Observable<any> {
        return this.http.get<any>(`${this.API_URL}/tenants/isTenant`);
    }


    login(email: string, password: string, redirectTo?: string) {
        return this.http.post<AuthResponseData>(`${this.API_URL}/auth/signin`, { email, password })
            // return this.http.post<AuthResponseData>("/api/auth/signin", { email, password })
            .pipe(
                tap(resData => {
                    this.onRedirectTo(redirectTo);
                    const expirationDate = new Date(new Date().getTime() + +resData.expiresIn * 1000);
                    const user = new User(
                        resData._id,
                        resData.email,
                        resData.name,
                        resData.lastName,
                        resData.tenantId,
                        resData.token,
                        expirationDate,
                        resData.roles,
                        resData.phone,
                        resData.birthday,
                        resData.location,
                        resData.enabled,
                        resData.status,
                        resData.permissions,
                        resData.showNews,
                        resData.instructionsNotToShow
                    );
                    posthog.identify(
                        user._id,
                        { tenantId: user.tenantId, email: user.email, name: user.name, lastname: user.lastName, db_id: user._id, phone: user.phone, birthday: user.birthday }
                    );
                    this.userService.user = user;
                    this.autoLogout(+resData.expiresIn * 1000);
                    localStorage.setItem('userData', JSON.stringify(user));
                    this.commerceService.obtainCommerce();
                    this.plansService.obtainPlansBundle();
                    this.salesChannelsService.loadSalesChannelsInfo();
                    this.commerceService.obtainExchangeRateBCV$();

                    if (user?.showNews) this.newsService.loadNews();

                    this.getTenant$().subscribe({
                        next: (res: any) => {
                            this.userService.tenant = res.data
                            posthog.group('tenant', res.data.tenantId, {
                                tenantId: res.data.tenantId,
                                companyName: res.data.companyName,
                                usePreferentialRate: res.data.usePreferentialRate,
                                useIGTF: res.data.useIGTF,
                                useIVA: res.data.useIVA,
                                createdAt: res.data.createdAt,
                                address: res.data.address,
                                ci: res.data.dni,
                                phone: res.data.phone,
                                ownerId: res.data.ownerId,
                                plan: res.plan,
                            })
                            posthog.capture('user logged in', { login_type: 'login' })
                        },
                        error: (err: any) => {
                            if (err?.error?.title && err?.error?.message) this.toastService.error(err.error.title, err.error.message);
                            else console.error(err);
                            this.logout();
                        }
                    })
                })
            )
    }

    onRedirectTo(redirectTo: string) {
        if (redirectTo) {
            if (redirectTo === 'pagar') this.router.navigate(['comercio/plans'], { queryParams: { pagar: true } });
        } else {
            this.router.navigate(['/dashboard']);
        }
    }

    logout() {
        this.userService.user = null;
        localStorage.removeItem('userData');

        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
            this.tokenExpirationTimer = null;
        }

        posthog.reset();

        this.router.navigate(['auth/login'], {
            queryParams: { expired: 'true' },
            replaceUrl: true
        });
    }

    autoLogout(expirationDuration: number) {
        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }

        this.tokenExpirationTimer = setTimeout(() => {
            this.logout();
            this.toastService.error('Error de Usuario', 'Expiró la sesión');
        }, expirationDuration);
    }

    autoLogin() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (!userData) return;

        // Check if token has expired based on timestamp
        const expirationDate = new Date(userData._tokenExpirationDate);
        if (expirationDate <= new Date()) {
            this.logout();
            this.toastService.error('Error de Usuario', 'Expiró la sesión');
            return;
        }

        const loadedUser = new User(
            userData._id,
            userData.email,
            userData.name,
            userData.lastName,
            userData.tenantId,
            userData._token,
            new Date(userData._tokenExpirationDate),
            userData.roles,
            userData.phone,
            userData.birthday,
            userData.location,
            userData.enabled,
            userData.status,
            userData.permissions,
            userData.showNews,
            userData.instructionsNotToShow
        );
        // VER SI SE PUEDE SIMPLIFICAR ELIMINANDO LA VARIABLE AUXILIAR USERDATA

        if (loadedUser.token) {
            this.userService.user = loadedUser;

            this.verifyToken$().subscribe({
                next: (res: any) => {
                    this.userService.user = new User(
                        res.data._id,
                        res.data.email,
                        res.data.name,
                        res.data.lastName,
                        res.data.tenantId,
                        userData._token,
                        new Date(userData._tokenExpirationDate),
                        res.data.roles,
                        res.data.phone,
                        res.data.birthday,
                        res.data.location,
                        res.data.enabled,
                        res.data.status,
                        res.data.permissions,
                        res.data.showNews,
                        res.data.instructionsNotToShow
                    );
                    const expirationDuration = new Date(userData._tokenExpirationDate).getTime() - new Date().getTime();
                    this.commerceService.obtainCommerce();
                    this.commerceService.obtainExchangeRateBCV$();
                    this.plansService.obtainPlansBundle();
                    this.autoLogout(expirationDuration);
                    this.salesChannelsService.loadSalesChannelsInfo();

                    if (res?.data?.showNews) this.newsService.loadNews();

                    posthog.identify(
                        res.data._id,
                        { tenantId: res.data.tenantId, email: res.data.email, name: res.data.name, lastname: res.data.lastName, db_id: res.data._id, phone: res.data.phone, birthday: res.data.birthday }
                    );
                    posthog.group('tenant', res.data.tenantId)
                    posthog.capture('user logged in', { login_type: 'autologin' })
                },
                error: (err: any) => {
                    if (err?.error?.title && err?.error?.message) this.toastService.error(err.error.title, err.error.message);
                    else console.error(err);
                    this.logout();
                }
            })

            this.getTenant$().subscribe({
                next: (res: any) => {
                    this.userService.tenant = res.data
                },
                error: (err: any) => {
                    if (err?.error?.title && err?.error?.message) this.toastService.error(err.error.title, err.error.message);
                    else console.error(err);
                    this.logout();
                }
            })
        } else {
            this.logout();
        }
    }

    changePassword$(data: any) {
        return this.http.post<any>(`${this.API_URL}/auth/changePassword`, data);
    }

    // Para verificar que el token que se encuentra
    getTenant$() {
        return this.http.get<any>(`${this.API_URL}/tenants/tenantId`);
    }
    // Para verificar que el token que se encuentra
    private verifyToken$() {
        return this.http.get<any>(`${this.API_URL}/auth/verified-user`);
    }

    private checkSession() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        if (userData && new Date(userData._tokenExpirationDate) <= new Date()) {
            this.logout();
            this.toastService.error('Error de Usuario', 'Expiró la sesión');
        }
    }

}
