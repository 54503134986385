<div class="container-fluid p-0">
    <div class="row bg-white rounded m-4 mt-3 p-3">
        <header class="col-sm-6 d-flex align-items-center gap-2 p-0" aria-label="breadcrumb">
            <img *ngIf="content.icon" [src]="content.icon" class="icon" height="24" width="24" />
            <h2 *ngIf="content.title" class="text-dark fw-bolder fs-3 m-0">
                {{ content.title | uppercase }}
            </h2>
        </header>
    </div>
</div>
