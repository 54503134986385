/**
 * This component is a basic implementation of an autocomplete input.,
 * but the main idea is to provide a simple way to create an autocomplete input.
 * The component is based on a state machine, so it's possible to override the default behavior of the component if needed.
 * The state machine is described by the `Machine` interface.
 * The component receives as input the following props:
 * - `value`: the value of the input that will be used to filter the options
 * - `rawItemsList`: an array of options to be displayed in the dropdown
 * - `label`: the label to be displayed next to the input
 * - `labelSecondary`: an optional secondary label to be displayed next to the label
 * - `placeholder`: the placeholder text to be displayed in the input
 * - `disabled`: a boolean to enable or disable the input
 * - `optionMap`: an object with the following properties:
 *   - `label`: the property name of the options to be used as the label, you can also provide an array of labels based on
 *      the shape of the objects in the `rawItemsList`, this feature only works on porperties in the first level 
 *   - `value`: the property name of the options to be used as the value
 *   - `id`: the property name of the options to be used as the id
 * - `lazy`: a boolean to enable the lazy loading of the options. If set to true, 
 *      the component will only load the options and return the selected item or the string the user is typing.
 *      If set to false, the component will load all the options when the component is initialized
 *      and will take the control about filtering the list of options based on the user's input.
 * - `mode`: this prop is used to set the component behavior. It can be 'select with search' or 'input with search', the default value is 'input', 
 * - `validations`: an object with the validation rules for the input. The object should have the following structure:
 *   - `min`: the minimum value to be accepted
 *   - `max`: the maximum value to be accepted
 *   - `required`: a boolean to set the input as required
 *   - `email`: a boolean to validate the input as an email
 *   - `pattern`: a regex pattern to be used to validate the input
 *   - `minlength`: the minimum length of the input to be accepted
 *   - `maxlength`: the maximum length of the input to be accepted
 * - `appareance`: it an optional prop to pass a string to set the appareance of the component. It can be 'simple' or 'normal'
 *    this last one is the default.
 * - `textTransform`: it an optional prop intended to transform the text of the label. It can be 'uppercase', 'lowercase',
 *    'capitalize' or 'none' this last one is the default.
 * The component emits the `selectedItem` event when an option is selected.
 * The component emits the `input` event when the input is modified.
 * 
 * Example of usage
 * 
 * <fina-autocomplete
 *              [(ngModel)]="formData.address" // or [ngModel] for template driven forms
 *              [formControl]="form.controls[address]" // for reactive forms
 *              [name]="'address'"
 *              [value]="customer.address"
 *              [label]="'Dirección'"
 *              [labelSecondary]="'(OPCIONAL)'"
 *              [placeholder]="'Dirección de habitación del cliente...'"
 *              [rawItemsList]="customersList"
 *              [optionMap]="{ label: ['address','name'], value: 'address' }"
 *              [alternativeStyle]="'simple'"
 *              [textTransform]="'capitalize'"
 *              [lazy]="true"
 *              [mode]="'select'"
 *              [validations]="{ pattern: { value: '^[+]?[0-9\\s]*$', message: 'El formato es inválido' } }" 
 *              (selectedItem)="onSelectCustomer($event)"
 *              (input)="onInput($event.target.value, 'address')"
 * ></fina-autocomplete>
 */


import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    forwardRef,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    ControlValueAccessor,
    FormControl,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
    ValidatorFn,
    Validators,
} from '@angular/forms';

import { Actions, LittleStateMachine, Machine } from '@common/classes/little-state-machine.class';
import { debounceTime, distinctUntilChanged, Subscription } from 'rxjs';

type MachineState =
    | 'options_hidden'
    | 'focussed_options_hidden'
    | 'focussed_options_visible';

type MachineEvent =
    | 'FOCUS'
    | 'CLICK_ON_ARROW'
    | 'BLUR'
    | 'TOGGLE'
    | 'FILTER'
    | 'OPTION_SELECTED'
    | 'RELOAD_DATA';

type MachineActions =
    | 'setSelectedItem'
    | 'clearNonValidSelectedOption'
    | 'filterList'
    | 'focusInput'
    | 'clearInput'
    | 'readData';

type AutocompleteMachine = Machine<MachineState, MachineEvent, MachineActions>;

type AutocompleteMachineActions = Actions<MachineActions>;

type ComponentMode = 'input' | 'select';

type MultilineLabel = {

}

type CompoundLabel = {
    key: string,
    fallback?: string,
    showFallback?: boolean
}

type Label = string
interface OptionMap {
    value?: string;
    label: Label | Array<Label  | CompoundLabel>;
    id?: string;
}

interface Option {
    value?: string;
    label: string;
    id?: string;
}

type ValidationsNames = 'min' | 'max' | 'required' | 'email' | 'pattern' | 'minlength' | 'maxlength';
type ValidationObject = {
    [K in ValidationsNames]?: {
        value?: any;
        message?: string;
    }
}

type TextTransforms = 'none' | 'capitalize' | 'uppercase' | 'lowercase' 
type Appearance = 'normal' | 'simple'
@Component({
    selector: 'fina-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AutocompleteComponent), // Ensure `forwardRef` is used
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => AutocompleteComponent), // Register for validation
            multi: true,
        },
    ],
})
export class AutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {
    @Input() value!: string;
    @Input() name!: string;
    @Input() label: string = '';
    @Input() labelSecondary: string = '';
    @Input() placeholder: string = '';
    @Input() rawItemsList: any[] = [];
    @Input() disabled = false;
    @Input() optionMap: OptionMap;
    @Input() validations: ValidationObject = {};
    @Input() lazy: boolean = false;
    @Input() mode: ComponentMode = 'input';
    @Input() appearance: Appearance = 'normal';
    @Input() optionsTextTransform: TextTransforms =  'none' ;
    @Output() selectedItem = new EventEmitter<Option>();

    @ViewChild('autocomplete') autocomplete!: ElementRef;
    @ViewChild('inputElementRef') inputElementRef!: ElementRef;

    inputFormControl: FormControl;

    private mappedItemsList: Option[] = [];
    private indexedItemsList = new Map<string, any>();
    private inputSuscription: Subscription;
    private _value: string = this.value;
    private onChange = (value: any) => {};
    private onTouched = () => {};
    filteredList: Option[] = [];

    autocompleteMachine: LittleStateMachine<
        AutocompleteMachine,
        AutocompleteMachineActions,
        MachineState,
        MachineEvent,
        MachineActions
    >;

    constructor() { }

    ngOnInit() {
    
       if (!this.optionMap) { throw new Error('optionMap prop is required'); }

       const {machine, actions} = this.getStateMachineConfig();

       this.autocompleteMachine = new LittleStateMachine(machine, actions, false);

       this.configureInput();
    }

    ngOnDestroy() {
        this.inputSuscription.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['rawItemsList'] && this.autocompleteMachine) {
            this.autocompleteMachine.send('RELOAD_DATA');
            this.configureInput();
        }
    }

    private getStateMachineConfig(): { machine: AutocompleteMachine, actions: AutocompleteMachineActions } {
        const machine: AutocompleteMachine = {
            initial: 'options_hidden',
            states: {
                options_hidden: {
                    on: {
                        FOCUS: { target: 'focussed_options_visible', actions: ['focusInput'] },
                        RELOAD_DATA: { target: 'options_hidden', actions: ['readData'] },
                        CLICK_ON_ARROW: { target: 'focussed_options_visible', actions: ['focusInput'] },
                    },
                    actions: { entry: ['readData'], exit: [] },
                },
                focussed_options_visible: {
                    on: {
                        BLUR: { target: 'options_hidden', actions: this.mode === 'select' ? ['clearNonValidSelectedOption'] : [] },
                        CLICK_ON_ARROW: 'focussed_options_hidden',
                        OPTION_SELECTED: { target: 'focussed_options_hidden', actions: ['setSelectedItem'], },
                        FILTER: { target: 'focussed_options_visible', actions: ['filterList'] },
                        RELOAD_DATA: { target: 'focussed_options_visible', actions: ['readData'] },
                    },
                    actions: { entry: ['filterList'], exit: [] },
                },
                focussed_options_hidden: {
                    on: {
                        BLUR: { target: 'options_hidden', actions: [] },
                        CLICK_ON_ARROW: { target: 'focussed_options_visible', actions: ['focusInput'] },
                        FOCUS: { target: 'focussed_options_visible', actions: ['focusInput'] },
                        RELOAD_DATA: { target: 'options_hidden', actions: ['readData', 'clearInput'] },
                    },
                    actions: { entry: [], exit: [] },
                },
            },
        };

        const actions: AutocompleteMachineActions = {
            readData: () => { this.readData(); },
            setSelectedItem: (payload) => { this.setSelectedItem(payload['option']); },
            clearNonValidSelectedOption: (payload) => { this.clearNonValidSelectedOption(payload['option']); },
            filterList: () => { this.filterList(); },
            focusInput: () => { this.focusInput(); },
            clearInput: () => { this.clearInput(); },
        };

        return {machine, actions};
    }

    private configureInput() {
       const validators = this.buildValidators(); 

       const oldInputValue = this.inputFormControl?.value || '';

       this.inputSuscription?.unsubscribe();

       this.inputFormControl = new FormControl(
           { value: oldInputValue, disabled: this.disabled },
           [ ...validators, ]
       );

       this.inputSuscription = this.inputFormControl.valueChanges
            .pipe(
                debounceTime(300),
                distinctUntilChanged()
            )
            .subscribe((value) => {
                this._value = value;
                this.onChange(value);
                this.autocompleteMachine.send('FILTER', { value });
            });
    }

    private buildValidators() {
        const validators: ValidatorFn[] = [];
        for (const key in this.validations) {
            switch (key) {
                case 'required':
                    validators.push(Validators.required);
                    break;
                case 'minlength':
                    validators.push(Validators.minLength(this.validations[key].value));
                    break;
                case 'maxlength':
                    validators.push(Validators.maxLength(this.validations[key].value));
                    break;
                case 'min':
                    validators.push(Validators.min(this.validations[key].value));
                    break;
                case 'max':
                    validators.push(Validators.max(this.validations[key].value));
                    break;
                case 'email':
                    validators.push(Validators.email);
                    break;
                case 'pattern':
                    validators.push(Validators.pattern(this.validations[key].value));
            }
        }

        if (this.mode === 'select') {
            const validOptionsIdentificators: Option[] = JSON.parse(JSON.stringify(this.filteredList));
            validators.push( this.optionValidator(validOptionsIdentificators));
        }

        return validators;
    }

    private readData() {
        this.mappedItemsList = [];
        this.indexedItemsList = new Map<string, any>();

        this.rawItemsList.forEach((item: any) => {
            if (typeof item === 'string') {
                const option: Option = { label: item, value: item, id: item };
                this.mappedItemsList.push(option);
                this.indexedItemsList.set(JSON.stringify(option.label), item);
            }

            if (typeof item === 'object') {
                const label = this.buildLabel(item);
                const option: Option = {
                    label,
                    value: item[this.optionMap.value],
                    id: item[this.optionMap.id],
                };

                this.mappedItemsList.push(option);
                this.indexedItemsList.set(JSON.stringify(option.label), item);
            }
        });

        this.filteredList = JSON.parse(JSON.stringify(this.mappedItemsList));
    }

    private buildLabel(item: any): string {
        if (!Array.isArray(this.optionMap.label)) {
            return item[this.optionMap.label];
        }

        let label = '';

        this.optionMap.label.forEach((objectLabel: CompoundLabel | Label, index: number) => {
            const key = typeof objectLabel === 'string' ? objectLabel : objectLabel.key;

            if (!(key in item))
                throw new Error(
                    `The key ${key} doesn't exist in ${JSON.stringify(item)} of the rawItemsList.`
                );

            const nextLabel = item[key];

            const fallback = typeof objectLabel !== 'string' && typeof objectLabel.fallback === 'string'
                ? `( ${objectLabel.fallback} )`
                : ''

            const alternativeLabel =
                typeof objectLabel === 'string'
                    ? `( no ${key} )`
                    : fallback;

            label += ` ${nextLabel && index > 0 ? ' - ' : ''}${ nextLabel ? nextLabel : alternativeLabel }`;
        });
        return label.trim();
    }

    private clearNonValidSelectedOption(option: Option) {
        if (!option) {
            this.selectedItem.emit(null);
            this.clearInput();
            return;
        }
    }

    private setSelectedItem(option: Option) {
        this.inputFormControl.setValue(option.value);
        this.inputFormControl.markAsDirty();
        this._value = option.value;
        const itemToReturn = this.indexedItemsList.get(JSON.stringify(option.label));
        this.onChange(this._value);
        this.onTouched();
        this.selectedItem.emit(itemToReturn);
    }

    private clearInput() {
        if (this.inputFormControl) {
            this.inputFormControl.setValue('');
            this._value = '';
        }
    }

    private filterList() {
        if (this.lazy) { return; }

        const value = this.inputFormControl?.value || '';
        this.filteredList = this.mappedItemsList.filter((item) =>
            item.label.toLowerCase().includes(value.toLowerCase())
        );
    }

    private focusInput(): void {
        this.inputElementRef.nativeElement.focus();
    }

    @HostListener('document:click', ['$event'])
    onClickOutside(event: MouseEvent): void {
        if (this.autocompleteMachine.state !== 'focussed_options_visible') {
            return;
        }

        const clickedInside = this.autocomplete.nativeElement.contains(event.target);

        if (!clickedInside) {
            this.autocompleteMachine.send('BLUR', { event: event });
        }
    }

    getErrorsMessages() {
        const errors = this.inputFormControl?.errors || null;

        if (!errors) {
            return null;
        }

        const errorsMapped = [];

        for (const error in errors) {
            let message = '';
            switch (error) {
                case 'required':
                    message = this.validations['required']?.message || 'Este campo es obligatorio';
                    break;
                case 'minlength':
                    message =
                        this.validations['minlength']?.message ||
                        `El campo debe tener por lo menos ${errors.minlength.requiredLength} carácteres`;
                    break;
                case 'maxlength':
                    message =
                        this.validations['maxlength']?.message ||
                        `El campo debe tener máximo ${errors.minlength.requiredLength} carácteres`;
                    break;
                case 'min':
                    message =
                        this.validations['min']?.message ||
                        `El valor mínimo permitido es ${errors.minlength.requiredLength}`;
                    break;
                case 'max':
                    message =
                        this.validations['max']?.message ||
                        `El valor máximo permitido es ${errors.minlength.requiredLength}`;
                    break;
                case 'email':
                    message =
                        this.validations['email']?.message ||
                        `El correo no tiene un formato válido`;
                    break;
                case 'pattern':
                    message =
                        this.validations['pattern']?.message ||
                        `El texto no tiene un formato válido`;
                    break;
                case 'noValidOption':
                    // message = 'La opción seleccionada no es válida';
                    break;
                default:
                    message = 'Error inesperado';
                    break;
            }
            const erroMapped = { error, message };
            errorsMapped.push(erroMapped);
        }

        return errorsMapped;
    }

    writeValue(value: string): void {
        if(!value && value !== '') return;

        this.inputFormControl?.setValue(value);
        this._value = value;
    }

    // Register the onChange function to notify Angular when the value changes
    registerOnChange(fn: any) {
        this.onChange = fn;
    }

    // Register the onTouched function to notify Angular when the input is touched
    registerOnTouched(fn: any) {
        this.onTouched = fn;
    }

    // Validator Method
    validate(control: AbstractControl): ValidationErrors | null {
        return this.inputFormControl?.errors;
    }

    private optionValidator(validValues: Option[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            const value = control.value;
            
            if (!value) return null;

            const optionFound: Option = validValues.find(option => option.value.toLowerCase().trim() === value.toLowerCase().trim());
            const isValidOption = Boolean(optionFound);

            return isValidOption ? null : { noValidOption: true };
        }
    }
}